<template>
  <pp-card>
    <pp-card-title>
      <div>
        <pp-token-preview :token="market.token">
          <template #image>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </template>
          <template #status-indicator>
            <pp-market-status-indicator
              class="ml-2"
              :address="market.address"
              tooltip-direction="bottom"
            ></pp-market-status-indicator>
          </template>
        </pp-token-preview>
        <span class="align-top ml-1 font-bold">
          <slot name="token-append"></slot>
        </span>
      </div>
      <div class="flex-grow"></div>
    </pp-card-title>

    <pp-card-body>
      <div>
        Mint, swap and add liquidity functions will be disabled in
        {{ market.status }} state.
        <a
          :href="`https://docs.pendle.finance/docs/information/core-concepts/yield-tokenization#${market.status}-state`"
          target="_blank"
          class="hover:underline text-p-blue-900"
        >
          Learn more.
        </a>
      </div>
    </pp-card-body>

    <pp-card-actions>
      <slot name="actions"></slot>
    </pp-card-actions>
  </pp-card>
</template>

<script>
  export default {
    props: {
      market: { type: Object, required: true },
    },
  }
</script>
