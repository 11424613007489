<template>
  <pp-alert
    v-if="isMainnet"
    class="bg-gradient-to-r from-water-600/[.15] to-[#89CDC226] mb-10"
  >
    <b>Notice:</b> From 24th Feb 2022 onwards, the staking function on Ethereum will be removed and all LPs will be able to claim incentives without the need to stake. You are encouraged to unstake from all of your current pools, then to continue to be eligible for incentives.
  </pp-alert>
</template>

<script>
  import { sdkChainId } from '@/app-config/network'

  export default {
    computed: {
      isMainnet() {
        return sdkChainId === 1
      },
    },
  }
</script>
