<template>
  <div class="container mx-auto">
    <GasSaverNotice class="mt-10 mb-0" />
    <div class="py-12 flex justify-center">
      <div v-if="inputToken" class="max-w-[600px] w-full">
        <pp-back-button :to="{ name: 'Farm' }"></pp-back-button>

        <div v-if="market && market.statusIs('inactive')" class="mt-5">
          <frozen-or-expired-alert-card :market="market" class="mb-5">
            <template #actions>
              <pp-link
                v-if="market.statusIs('frozen')"
                :to="{
                  name: 'Redeem',
                  params: {
                    tokenAddress1: market.tokenIssuer.ytAddress,
                    tokenAddress2: market.quoteToken.address,
                  },
                }"
                text="Redeem here"
                class="text-water-600"
              ></pp-link>
            </template>
          </frozen-or-expired-alert-card>
        </div>

        <div class="mt-5 space-y-16">
          <pp-card class="border border-p-gray-200">
            <template v-if="tabs.length > 1">
              <div class="flex space-x-1 p-1">
                <pp-tab
                  v-for="tab in tabs"
                  :key="tab.text"
                  size="lg"
                  tag="router-link"
                  variant="text"
                  :to="{ name: tab.routeName, params: $route.params }"
                  border="bottom"
                  :active="$route.matched.map((route) => route.name).includes(tab.routeName)"
                >
                  {{ tab.text }}
                </pp-tab>
              </div>

              <hr class="-mt-1" />
            </template>

            <pp-card-title>
              <div>
                <pp-token-preview :token="inputToken"></pp-token-preview>
                <span class="align-top ml-1 font-bold">
                  <slot name="token-append"></slot>
                </span>
              </div>
              <div class="flex-grow"></div>
            </pp-card-title>

            <router-view></router-view>
          </pp-card>
        </div>
      </div>

      <div v-else class="w-96">
        <PpNotFound />
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Token from '@/domains/entities/Token'
  import PendleMarket from '@/domains/entities/PendleMarket'
  import FrozenOrExpiredAlertCard from '@/components/FrozenOrExpiredAlertCard.vue'
  import GasSaverNotice from '@/components/GasSaverNotice.vue'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    components: {
      FrozenOrExpiredAlertCard,
      GasSaverNotice,
    },
    computed: {
      inputToken() {
        const token = Token.query().find(this.$route.params.inputTokenAddress)
        return token?.address ? token : null
      },
      market() {
        return PendleMarket.query().find(this.inputToken?.address)
      },
      tabs() {
        const isMainnet = sdkChainId === 1
        if (this.market && !this.market.pairName.includes('wxBTRFLY')) {
          return [
            { routeName: 'Liquidity', text: 'Liquidity' },
            { routeName: 'StakeIndex', text: isMainnet ? 'Unstake' : 'Stake' },
          ]
        }
        return []
      },
    },
  })
</script>
